<template>
    <div class="modal" v-if="modelValue" :class="{ active : modelValue }">
        <div class="modal_container">
            <div class="modal_header border">
                <div class="modal_info">
                    <h1>Change Member ID</h1>
                </div>
                <button class="close_btn left_out" @click="closeModal()"><i class="fas fa-long-arrow-alt-right"></i></button>
            </div>
            <div class="modal_body">
                <div class="setting_wpr">
                    <Form @submit="handleChangeMemberId" v-slot="{ errors }">
                        <contact-email-component v-model="form" :errors="errors" component-type="change member id" :success-handler="closeModal" ref="email-component" />
                        <div class="action_wpr mt-5">
                            <button :disabled="emailLoader" type="button" class="btn cancel_btn" @click="closeModal()">Cancel</button>
                            <button :disabled="emailLoader" class="btn save_btn" type="button" @click="handleChangeMemberId"><i class="fa fa-spinner fa-spin" v-if="emailLoader"></i>{{ emailLoader ? ' Sending' : 'Send' }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { Form } from 'vee-validate'
    import { mapState } from 'vuex'
    import { defineAsyncComponent } from 'vue'

    const ContactEmailComponent = defineAsyncComponent(() => import('@/pages/contact/components/EmailComponent'))

    export default {
        name: 'ChangeMemberId',

        data () {
            return {
                form: {
                    contact_id: '',
                    source: 'contacts',
                    subject: 'Update your member ID',
                    email: `<p>Hi {{ first_name }}</p>
                            <p><br />{{ company_owner }} has requested you to change your member ID</p>
                            <p>Click the link below to update your member ID</p>
                            {{ member_id_change_link }}
                            <br />
                            <p>Cheers!</p>
                            <p>Team {{company_name}}</p>`,
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: '',
                    schedule_action: '/contact/member-id-change',
                },
            }
        },

        props: {
            modelValue: Boolean,
            contacts: Array,
        },

        emits: ['update:modelValue'],

        components: {
            Form,
            ContactEmailComponent,
        },

        watch: {
            modelValue(val) {
                if (val) {
                    const vm = this;

                    vm.resetForm();
                    document.body.classList.add('modal-open');
                } else {
                    document.body.classList.remove('modal-open');
                }
            },
        },

        computed: mapState({
            user: state => state.authModule.user,
            emailLoader: state => state.contactModule.emailLoader,
        }),

        methods: {
            closeModal () {
                const vm = this;

                vm.$emit('update:modelValue', false);
            },

            resetForm () {
                const vm = this;

                vm.form = {
                    contact_id: vm.contacts.join(','),
                    source: 'contacts',
                    subject: 'Update your member ID',
                    email: `<p>Hi {{ first_name }}</p>
                            <p><br />{{ company_owner }} has requested you to change your member ID</p>
                            <p>Click the link below to update your member ID</p>
                            {{ member_id_change_link }}
                            <br />
                            <p>Cheers!</p>
                            <p>Team {{company_name}}</p>`,
                    is_email_conf: 0,
                    is_email_logo: 0,
                    is_signature: 0,
                    signature_id: 0,
                    show_email_logo: 0,
                    email_logo: vm.user.logo,
                    schedule_action: '/contact/member-id-change',
                };
            },

            handleChangeMemberId () {
                const vm = this;

                const emailComponent = vm.$refs['email-component'];

                emailComponent.handleEmailSend('changeMemberIDEmail');
            },
        },
    }
</script>
